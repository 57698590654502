.krumo-root a {
  color: #333;
}
.krumo-root .krumo-element,
.krumo-root .krumo-preview {
  color: #555;
}
.main-sidebar {
  overflow: hidden;
}
.clearboth {
  clear: both;
}
.mr-0 {
  margin-right: 0rem;
}
.ml-0 {
  margin-left: 0rem;
}
.mt-0 {
  margin-top: 0rem;
}
.mb-0 {
  margin-bottom: 0rem;
}
.my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.mx-0 {
  margin-left: 0rem;
  margin-right: 0rem;
}
.pl-0 {
  padding-left: 0rem;
}
.pr-0 {
  padding-right: 0rem;
}
.pt-0 {
  padding-top: 0rem;
}
.pb-0 {
  padding-bottom: 0rem;
}
.py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.px-0 {
  padding-right: 0rem;
  padding-left: 0rem;
}
.mr-1 {
  margin-right: 0.5rem;
}
.ml-1 {
  margin-left: 0.5rem;
}
.mt-1 {
  margin-top: 0.5rem;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mx-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.pl-1 {
  padding-left: 0.5rem;
}
.pr-1 {
  padding-right: 0.5rem;
}
.pt-1 {
  padding-top: 0.5rem;
}
.pb-1 {
  padding-bottom: 0.5rem;
}
.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-1 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.mr-2 {
  margin-right: 1rem;
}
.ml-2 {
  margin-left: 1rem;
}
.mt-2 {
  margin-top: 1rem;
}
.mb-2 {
  margin-bottom: 1rem;
}
.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.pl-2 {
  padding-left: 1rem;
}
.pr-2 {
  padding-right: 1rem;
}
.pt-2 {
  padding-top: 1rem;
}
.pb-2 {
  padding-bottom: 1rem;
}
.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-2 {
  padding-right: 1rem;
  padding-left: 1rem;
}
.mr-3 {
  margin-right: 1.5rem;
}
.ml-3 {
  margin-left: 1.5rem;
}
.mt-3 {
  margin-top: 1.5rem;
}
.mb-3 {
  margin-bottom: 1.5rem;
}
.my-3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mx-3 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.pl-3 {
  padding-left: 1.5rem;
}
.pr-3 {
  padding-right: 1.5rem;
}
.pt-3 {
  padding-top: 1.5rem;
}
.pb-3 {
  padding-bottom: 1.5rem;
}
.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-3 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.mr-4 {
  margin-right: 2rem;
}
.ml-4 {
  margin-left: 2rem;
}
.mt-4 {
  margin-top: 2rem;
}
.mb-4 {
  margin-bottom: 2rem;
}
.my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mx-4 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.pl-4 {
  padding-left: 2rem;
}
.pr-4 {
  padding-right: 2rem;
}
.pt-4 {
  padding-top: 2rem;
}
.pb-4 {
  padding-bottom: 2rem;
}
.py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.px-4 {
  padding-right: 2rem;
  padding-left: 2rem;
}
.mr-5 {
  margin-right: 2.5rem;
}
.ml-5 {
  margin-left: 2.5rem;
}
.mt-5 {
  margin-top: 2.5rem;
}
.mb-5 {
  margin-bottom: 2.5rem;
}
.my-5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.mx-5 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.pl-5 {
  padding-left: 2.5rem;
}
.pr-5 {
  padding-right: 2.5rem;
}
.pt-5 {
  padding-top: 2.5rem;
}
.pb-5 {
  padding-bottom: 2.5rem;
}
.py-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.px-5 {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
/*# sourceMappingURL=custom.css.map */